.saveDraftDiv {
	display: flex;
	align-items: center;
}

.draftMsg {
	font-size: 14px;
	color: var(--theme-color-light);
}

/* SvgButton */
.svg {
	width: 15px;
	height: 15px;
}

.svgText {
	font-size: 15px;
	margin-left: 4px;
}

.svgButton {
	display: flex;
	align-items: center;
	height: 29px;
	padding: 7px;
	outline: none;
	border: none;
	cursor: pointer;
	background-color: var(--theme-background-color);
	fill: var(--theme-color);
	color: var(--theme-color);
	margin-right: 2px;
	border-radius: 5px;
}

.svgButton:hover,
.svgButton:focus-visible {
	background-color: var(--theme-hover-background-color);
}
