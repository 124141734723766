.div {
	user-select: none;
	margin: 8px 0px;
}

.img {
	max-width: 100%;
}

.selected {
	box-shadow: 0 0 0 3px #b4d5ff;
}
