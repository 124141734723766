.article {
	margin-top: 20px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	position: relative;
}

.article:hover {
	border: 1px solid var(--theme-border-hover-color);
}

.articleLink {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.mainSection {
	padding: 12px 17px;
}

/* Title */
.title {
	display: flex;
	align-items: center;
	fill: var(--theme-color);
}

.h1 {
	font-size: 15px;
}

.svg {
	margin-right: 7px;
	width: 15px;
	height: 15px;
}

/* Profile */
.profile {
	margin: 10px 0px;
	display: flex;
}

.img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: block;
	object-fit: cover;
}

.description {
	width: 100%;
	margin-left: 15px;
}

.name {
	font-weight: 500;
}

/* Text */
.truncateChild {
	color: var(--tipstory-blue);
}

/* Icon */
.icon {
	display: flex;
	align-items: center;
	margin-top: 10px;
	padding: 0;
}

.iconItem {
	display: flex;
	align-items: center;
	margin-left: 5px;
}

.marginLeft0 {
	margin-left: 0px;
}

.iconSvg {
	height: 19px;
	width: 19px;
}

.iconLabel {
	color: var(--theme-color-grey);
	font-size: 14px;
	margin-left: 3px;
	user-select: none;
}
