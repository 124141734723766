.header {
	margin-top: 30px;
	background: var(--theme-hover-background-color);
	padding: 1px 20px 5px 20px;
	border-radius: 7px 7px 0px 0px;
}

.p {
	margin-top: 5px;
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

.editor {
	border: 1px solid var(--theme-border-color);
	padding: 0px 20px 15px 20px;
	border-radius: 7px;
}

.copyBoxDiv {
	background: var(--theme-hover-background-color);
	padding: 15px 20px 15px 20px;
	border-radius: 0px 0px 7px 7px;
}

/* CopyBox */
/* .copyBox {
	width: 100%;
	display: flex;
	align-items: center;
	height: 27px;
}

.copyBoxName {
	border: 1px solid var(--theme-border-color);
	border-radius: 5px 0px 0px 5px;
	box-sizing: border-box;
	font-size: 15px;
	width: 100px;
	height: 27px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.copyBoxLink {
	border: 1px solid var(--theme-border-color);
	border-left: 0px;
	border-right: 0px;
	box-sizing: border-box;
	font-size: 15px;
	width: calc(100% - 185px);
	height: 27px;
	padding: 0px 7px;
	outline: none;
	background: var(--theme-code-background-color);
}

.copyBoxButton {
	width: 85px;
	height: 27px;
	box-sizing: border-box;
} */
