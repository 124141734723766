.commentPanel {
	margin-top: 20px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	padding: 12px 17px;
	font-size: 14px;
	position: relative;
}

.commentPanel:hover {
	border: 1px solid var(--theme-border-hover-color);
}

/* Title */
.titleLink:hover {
	text-decoration: underline;
}

.blueTitleLink {
	color: var(--tipstory-blue);
}

.title {
	display: flex;
	align-items: center;
	fill: var(--theme-color);
	margin-bottom: 10px;
}

.blueTitle {
	fill: var(--tipstory-blue);
}

.blueH1 {
	color: var(--tipstory-blue);
}

.h1 {
	font-size: 15px;
}

.svg {
	margin-right: 7px;
	width: 15px;
	height: 15px;
}

.linkSvg {
	margin-left: 7px;
	width: 11px;
	height: 11px;
}

/* Comment */
.comment {
	display: flex;
}

.commentDiv {
	width: 100%;
	margin-left: 15px;
}

/* Profile */
.profileImg {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

/* Headline */
.name {
	font-weight: 500;
}

.time {
	margin-left: 10px;
	opacity: 0.8;
	font-size: 13px;
	color: var(--theme-color-grey);
}

/* CommentText */
.commentText {
	margin: 2px 0px 5px 0px;
	white-space: pre-wrap;
}

.commentTextButton {
	opacity: 0.8;
	color: var(--theme-color-grey);
	font-size: 14px;
}
