.header {
	margin-top: 20px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.h1 {
	font-size: 25px;
	color: var(--tipstory-blue);
}

.p {
	margin-top: 10px;
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

.link {
	color: var(--tipstory-blue);
	text-decoration: underline;
}

/* Feedback Form */
.formDiv {
	padding: 10px 15px;
	border: 2px solid var(--theme-border-color);
	border-radius: 7px;
	margin-top: 15px;
	margin-bottom: 10px;
}

.buttonDiv {
	display: flex;
	justify-content: center;
	margin-top: 7px;
}

.button {
	padding: 5px 15px 6px 15px;
}

.msg {
	margin-top: 5px;
}

.error {
	margin-top: 5px;
	color: rgb(245, 35, 45);
	font-weight: 500;
	font-size: 16px;
}

/* Input */
.inputDiv {
	margin-bottom: 10px;
}

.label {
	color: var(--tipstory-blue);
	font-size: 16px;
	font-weight: 500;
	margin-left: 2px;
	display: block;
	margin-bottom: 3px;
}

.input {
	box-sizing: border-box;
	width: 100%;
	font-size: 15px;
	line-height: 23px;
	background: none;
	border: none;
	border-bottom: 1px solid var(--theme-border-color);
}

.input:focus {
	outline: none;
	border-bottom: 1px solid var(--tipstory-blue);
}
