.section {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--theme-modal-background-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}

.div {
	position: relative;
	width: 100%;
	max-width: 500px;
	background-color: var(--theme-background-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 20px 0px 20px;
	border-radius: 10px;
	border: 1px solid var(--theme-border-color);
}

.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.btnTimes {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: none;
}

.btnTimes:hover,
.btnTimes:focus {
	background: var(--theme-hover-background-color);
}

.timesSvg {
	width: 20px;
	height: 20px;
}

.error {
	color: var(--tipstory-blue);
	font-weight: bold;
}

.draftBox {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 20px 0px 30px 0px;
	max-height: 362px;
	overflow: auto;
}

/* DraftLearning */
.draftLearning {
	border: 1px solid var(--theme-border-color);
	width: 100%;
	border-radius: 5px;
	padding: 5px 10px;
	box-sizing: border-box;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 8px;
	user-select: none;
}

/* Title */
.title {
	display: flex;
	align-items: center;
	fill: var(--theme-color);
}

.h1 {
	font-size: 15px;
}

.svg {
	margin-right: 7px;
	width: 15px;
	height: 15px;
}

.saveDate {
	color: var(--theme-color-grey);
	font-size: 14px;
}

.buttonGroup {
	padding: 4px 0px;
}

.deleteButton {
	margin-left: 8px;
}
