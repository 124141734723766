/* Nav */
.navBar {
	margin-top: 10px;
	width: 100%;
	box-sizing: border-box;
	height: 40px;
	border-top: 1px solid var(--theme-border-color);
	border-bottom: 1px solid var(--theme-border-color);
	display: flex;
	justify-content: center;
	font-size: 15px;
	font-weight: 500;
}

/* NavTab */
.navTab {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 38.5px;
	color: var(--theme-color-grey);
	box-sizing: border-box;
	min-width: 100px;
	padding: 0px 10px;
}

.navTab:hover,
.navTab:focus {
	background-color: var(--theme-hover-background-color);
}

.activeTab {
	color: var(--tipstory-blue) !important;
	background-color: var(--theme-background-color) !important;
	border-bottom: 1.5px solid rgb(5, 151, 242, 0.7);
}

@media (max-width: 401px) {
	.navTab {
		min-width: 60px;
		font-size: 13px;
	}
}
