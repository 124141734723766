.schoolsOverflow {
	display: flex;
	width: 100%;
	overflow: hidden;
}

.schools {
	display: flex;
	margin-top: 20px;
	width: 100%;
	-webkit-animation: loop 90s linear infinite;
	-moz-animation: loop 90s linear infinite;
	-o-animation: loop 90s linear infinite;
	animation: loop 90s linear infinite;
}

@keyframes loop {
	0% {
		transform: translateX(0%);
	}
	100% {
		transform: translateX(-240%);
	}
}
.school {
	box-sizing: border-box;
	height: 50px;
	margin-right: 10px;
	margin-bottom: 10px;
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	opacity: 0.6;
	border-radius: 5px;
	user-select: none;
}
