/* Tab */
.tab {
	padding: 4px 4px;
	border: 1px solid transparent;
}

.tabOver {
	background-color: var(--theme-select-background-color);
	border: 1px solid var(--tipstory-blue);
}

/* Link */
.tabOver > .link {
	border: 1px solid var(--theme-border-color);
}

.link {
	user-select: none;
	border: 1px solid transparent;
	background: var(--theme-background-color);
	display: flex;
	align-items: center;
	padding: 8px 9px;
	cursor: pointer;
	border-radius: 7px;
	font-size: 14px;
	font-weight: 500;
}

.link:hover,
.link:focus-visible {
	background: var(--theme-hover-background-color);
}
