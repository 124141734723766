.section {
	padding: 15px 30px 20px 30px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
}

/* Save */
.save {
	display: flex;
	align-items: center;
	margin-bottom: 8px;
	font-weight: 500;
	font-size: 15px;
}

.bookmarkSvg {
	width: 19px;
	height: 19px;
	margin-right: 8px;
}

.originalLink {
	margin-left: 3px;
	outline: none;
	background: none;
	border: none;
	font-size: 15px;
}

.originalLink:focus,
.originalLink:hover {
	text-decoration: underline;
	cursor: pointer;
}

/* Source */
.source {
	display: flex;
	align-items: center;
	font-weight: 500;
	fill: var(--theme-color);
}

.svgIcon {
	width: 19px;
	height: 19px;
	margin-right: 8px;
}

/* TagList */
.tagList {
	margin-top: 6px;
	display: flex;
	flex-wrap: wrap;
}

.tag {
	color: var(--tipstory-blue);
	border: 1px solid var(--tipstory-blue);
	padding: 1px 8px 3px 8px;
	border-radius: 7px;
	margin-right: 10px;
	margin-top: 6px;
}

.lastTag {
	margin-right: 0px;
}

@media (max-width: 700px) {
	.section {
		padding: 15px 20px 20px 20px;
	}
}
