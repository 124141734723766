.readCard {
	width: 190px;
	box-sizing: border-box;
	padding: 0px 5px;
	margin-right: 3px;
	margin-bottom: 10px;
}

.link {
	box-sizing: border-box;
	width: 100%;
	height: 150px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	font-size: 14px;
}

/* Tag */
.top {
	box-sizing: border-box;
	padding: 7px;
}

.tag {
	width: fit-content;
	color: var(--tipstory-blue);
	border: 1px solid var(--tipstory-blue);
	padding: 3px 7px;
	border-radius: 7px;
}

/* Bottom */
.bottom {
	width: 100%;
	box-sizing: border-box;
	padding: 7px;
	border-top: 1px solid var(--theme-border-color);
}

.link:hover > .bottom,
.link:focus > .bottom {
	background-color: var(--theme-select-background-color);
	border-radius: 0px 0px 7px 7px;
}

/* Title */
.title {
	font-size: 14px;
}

/* Footer */
.footer {
	margin-top: 1px;
	color: var(--theme-color-grey);
	fill: var(--theme-color-grey);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.leftFooter {
	display: flex;
	align-items: center;
}

.svg {
	width: 13px;
	height: 13px;
	margin-right: 3px;
	margin-top: 2px;
}

/* IconGroup */
.iconGroup {
	display: flex;
}

.icon {
	margin-left: 3px;
	width: 17px;
	height: 17px;
}
