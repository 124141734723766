.buttonGroup {
	margin-top: 10px;
	display: flex;
	justify-content: flex-end;
}

/* LinkButton */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.button {
	composes: preserveOutline;
	user-select: none;
	border: none;
	display: flex;
	align-items: center;
	background: none;
	padding: 8px 9px;
	cursor: pointer;
	border-radius: 7px;
	fill: var(--theme-color-grey);
	color: var(--theme-color-grey);
	font-size: 14px;
	font-weight: bold;
}

.button:hover,
.button:focus-visible {
	background: var(--theme-hover-background-color);
}

.buttonSvg {
	width: 15px;
	height: 15px;
	margin-right: 4px;
}
