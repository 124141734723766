.linkInfo {
	margin-top: 7px;
}

.linkInfoH1 {
	font-size: 16px;
	color: var(--tipstory-blue);
	margin-bottom: 7px;
}

.optional {
	font-size: 15px;
	color: var(--theme-color-grey);
	font-weight: normal;
}

/* linkInfoInput */
.linkInfoInput {
	width: 100%;
	resize: none;
	outline: none;
	border: none;
	border-bottom: 1px solid var(--theme-background-color);
	padding-bottom: 5px;
	overflow: hidden;
}

.linkInfoInput:hover {
	border-bottom: 1px solid var(--tipstory-blue);
}

/* LinkCard */
.linkCard {
	width: 100%;
	max-width: 400px;
	min-height: 40px;
	margin-top: 10px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	position: relative;
}

/* LinkCard - DeleteButton */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.deleteButton {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: var(--theme-hover-background-color);
	z-index: 10;
}

.deleteButton:hover,
.deleteButton:focus {
	background: var(--theme-border-color);
}

.deleteButtonSvg {
	width: 20px;
	height: 20px;
}

/* LinkCard - linkCardEmbed */
.linkCardEmbed {
	width: 100%;
	max-width: 400px;
	height: 200px;
	object-fit: contain;
	border-bottom: 1px solid var(--theme-border-color);
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}

.linkCardTextDiv {
	padding: 0px 10px 8px 10px;
	opacity: 0.8;
}

.bold {
	font-weight: bold;
}

.linkCardText {
	font-size: 15px;
	line-height: normal;
}

.linkCardLink {
	font-size: 14px;
	line-height: normal;
	opacity: 0.8;
}
