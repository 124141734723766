.main {
	margin-top: 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

/* Error */
.error {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
	color: var(--tipstory-blue);
	font-size: 21px;
}
