/* Icons */
.iconGroup,
.icon {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.notLastIcon {
	margin-right: 20px;
}

.svg {
	margin-right: 5px;
	height: 24px;
	width: 24px;
}

@media (max-width: 455px) {
	.iconGroup {
		font-size: 15px;
	}
	.notLastIcon {
		margin-right: 10px;
	}
}
