.comment {
	margin-top: 15px;
	display: flex;
	font-size: 14px;
}

.commentDiv {
	width: 100%;
}

/* Profile */
.profileImg {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-right: 15px;
	object-fit: cover;
}

/* Headline */
.name {
	font-weight: 500;
}

.time {
	margin-left: 10px;
	opacity: 0.8;
	font-size: 13px;
	color: var(--theme-color-grey);
}

/* CommentText */
.commentText {
	margin: 2px 0px 5px 0px;
	white-space: pre-wrap;
}

.commentTextButton {
	opacity: 0.8;
	color: var(--theme-color-grey);
	font-size: 14px;
}

/* ButtonGroup */
.buttonGroup {
	opacity: 0.8;
	display: flex;
}

.textButton {
	font-size: 13px;
}

.textButtonMarginLeft {
	margin-left: 10px;
}
