.monthlyTask {
	margin-top: 20px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
	margin-bottom: 10px;
}

.blur {
	user-select: none;
	opacity: 0.5;
}

.h1 {
	font-size: 20px;
	margin-bottom: 10px;
	color: var(--theme-color-light);
}

.error {
	color: var(--tipstory-blue);
	font-weight: 500;
	margin-bottom: 10px;
}

.buttonDiv {
	margin-top: 5px;
	display: flex;
}

.monthSvg {
	fill: var(--theme-color-light);
	width: 33px;
	height: 33px;
	margin-right: 10px;
	fill: var(--tipstory-blue);
	opacity: 0.6;
}

.monthSvgActive {
	opacity: 1;
}

/* taskCheckbox */
.taskDiv {
	font-size: 18px;
	color: var(--theme-color-light);
	display: flex;
	margin-bottom: 4px;
}

.taskRightDiv {
	margin-left: 5px;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.task {
	width: 280px;
	margin-right: 10px;
}

.checkedTask {
	text-decoration: line-through;
}

/* ProgressDots */
.progressDotDiv {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.progressDot {
	width: 13px;
	height: 13px;
	border: 3px solid var(--tipstory-blue);
	border-radius: 100%;
	margin-right: 5px;
}

.progressDotActive {
	background: var(--tipstory-blue);
}
