.shareButton {
	position: relative;
}

.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.button {
	composes: preserveOutline;
	user-select: none;
	border: none;
	display: flex;
	align-items: center;
	background: none;
	padding: 8px 9px;
	cursor: pointer;
	border-radius: 7px;
	fill: var(--theme-color-grey);
	color: var(--theme-color-grey);
	font-size: 14px;
	font-weight: bold;
}

.button:hover,
.button:focus-visible {
	background: var(--theme-hover-background-color);
}

.svg {
	width: 18px;
	height: 18px;
	padding: 1px;
	margin-right: 4px;
}

/* Dropdown List */
.dropdownList {
	width: 140px;
	position: absolute;
	right: 0;
	background-color: var(--theme-background-color);
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
}

.dropdownItem {
	width: 100%;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	font-size: 15px;
	display: flex;
	align-items: center;
	color: var(--theme-color-light);
	fill: var(--theme-color-light);
	padding: 7px 10px;
}

.dropdownItem:focus,
.dropdownItem:hover {
	background: var(--theme-hover-background-color);
}

.dropdownSvg {
	width: 16px;
	height: 16px;
}

.dropdownText {
	margin-left: 10px;
}
