.tipStoryScholar {
	background-color: var(--theme-hover-background-color);
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px 0px;
	width: 100%;
}

.headingDiv {
	display: flex;
	align-items: center;
}

.heading {
	color: var(--tipstory-blue);
}

.text {
	margin: 20px 0px;
	max-width: 650px;
	font-weight: 500;
	padding: 0px 20px;
	text-align: center;
}

.button {
	font-size: 15px;
	padding: 6px 14px 7px 12px;
}

.bottomText {
	font-size: 14px;
	color: var(--theme-color-grey);
}

.badgeList {
	display: flex;
	margin-top: 5px;
	margin-right: 2px;
}

.svg {
	width: 33px;
	height: 33px;
	margin-right: 5px;
}

.bronze {
	animation: bronzeFilter 10s infinite;
}

@keyframes bronzeFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(247, 137, 74));
		filter: drop-shadow(0px 0px 1.5px rgb(247, 137, 74));
		stroke: rgb(247, 137, 74);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

.silver {
	animation: silverFilter 10s infinite;
}

@keyframes silverFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(204, 204, 204));
		filter: drop-shadow(0px 0px 1.5px rgb(204, 204, 204));
		stroke: rgb(204, 204, 204);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

.gold {
	animation: goldFilter 10s infinite;
}

@keyframes goldFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(255, 185, 0));
		filter: drop-shadow(0px 0px 1.5px rgb(255, 185, 0));
		stroke: rgb(255, 185, 0);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

@media (max-width: 768px) {
	.heading {
		font-size: 25px;
	}
}
