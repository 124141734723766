.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.button {
	composes: preserveOutline;
	user-select: none;
	border: none;
	display: flex;
	align-items: center;
	background: none;
	padding: 8px 9px;
	cursor: pointer;
	border-radius: 7px;
	color: var(--theme-color-grey);
	fill: var(--theme-color-grey);
	font-size: 14px;
	font-weight: bold;
}

.button:hover,
.button:focus-visible {
	background: var(--theme-hover-background-color);
}

.buttonSvg {
	width: 20px;
	height: 20px;
	margin-right: 4px;
}

.helpfulActive {
	color: var(--tipstory-blue);
}

.readActive {
	color: #2dcb64;
}

.interestingActive {
	color: #ffbd59;
}
