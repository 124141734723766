.section {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--theme-modal-background-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}

.div {
	position: relative;
	max-width: 450px;
	width: 100%;
	box-sizing: border-box;
	background-color: var(--theme-background-color);
	padding: 30px 30px;
	border-radius: 10px;
}

/* Close Button */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.closeButton {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: none;
}

.closeButton:hover,
.closeButton:focus {
	background: rgb(230, 230, 230);
}

.closeSvg {
	width: 20px;
	height: 20px;
}

/* Error */
.error {
	text-align: center;
	color: var(--tipstory-blue);
	margin-bottom: 10px;
	font-weight: bold;
}

/* ImgInput */
.imgInput {
	margin-bottom: 10px;
}

.imgLabel {
	color: var(--tipstory-blue);
	font-size: 15px;
	font-weight: 500;
	margin-left: 2px;
	display: block;
	margin-bottom: 5px;
}

.imageInputRef {
	display: none;
}

.imgButton {
	composes: preserveOutline;
	border: none;
	cursor: pointer;
	background: none;
	border: 2.2px solid transparent;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imgButton:hover,
.imgButton:focus-visible {
	opacity: 0.9;
	border: 2.2px solid var(--tipstory-blue);
	background-color: var(--tipstory-blue);
}

.img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	object-fit: cover;
}

/* Input */
.inputDiv {
	margin-bottom: 10px;
}

.label {
	color: var(--tipstory-blue);
	font-size: 15px;
	font-weight: 500;
	margin-left: 2px;
	display: block;
	margin-bottom: 3px;
}

.input {
	width: 100%;
	font-size: 15px;
	line-height: 21px;
	border: none;
	border-bottom: 1px solid var(--theme-border-color);
}

.input:focus {
	outline: none;
	border-bottom: 1px solid var(--tipstory-blue);
}

/* Button */
.submitDiv {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
}

.cancelButton {
	padding: 4px 10px 5px 10px;
}

.saveButton {
	padding: 4px 15px 5px 15px;
}
