/* Error */
.error {
	padding: 0px 20px;
	color: var(--tipstory-blue);
	font-size: 16px;
}

/* Empty Msg */
.emptyMsg {
	padding: 0px 20px;
	color: var(--theme-color-grey);
	user-select: none;
}

/* Journey */
.journey {
	outline: none;
	width: 100%;
	min-height: 50vh;
	padding-bottom: 10px;
	display: flex;
	justify-content: center;
}

.journeyContainer {
	max-width: 965px; /* Each journey is 193px, 193px*5 */
	width: 100%;
	display: flex;
	flex-direction: column;
}

.chapterTitle {
	font-size: 14px;
	user-select: none;
	margin-left: 10px;
	margin-bottom: 5px;
}

.chapterContainer {
	display: flex;
	flex-wrap: wrap;
}

.learningTitle {
	font-size: 14px;
	user-select: none;
	margin-left: 10px;
	margin-bottom: 10px;
	margin-top: 10px;
}

@media (max-width: 1072px) {
	.journeyContainer {
		max-width: 772px;
	}
}

@media (max-width: 879px) {
	.journeyContainer {
		max-width: 579px;
	}
}

@media (max-width: 686px) {
	.journeyContainer {
		max-width: 386px;
	}
}
