/* Learning Article */
.learningArticle {
	padding: 15px 30px 20px 30px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	margin-top: 20px;
}

.pinH1 {
	font-size: 15px;
	display: flex;
	align-items: center;
	color: var(--tipstory-blue);
	fill: var(--tipstory-blue);
	margin-bottom: 8px;
}

.pinSvg {
	width: 15px;
	height: 15px;
	margin-right: 3px;
}

.h1 {
	font-size: 15px;
}

.divider {
	border-top: 1px solid var(--theme-border-color);
	margin: 10px 0px;
}

@media (max-width: 700px) {
	.learningArticle {
		padding: 15px 20px 20px 20px;
	}
}
