.home {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	font-size: 15px;
	line-height: normal;
	margin-bottom: 50px;
}

.mainLanding {
	width: 100%;
}

.main {
	width: 100%;
	max-width: 700px;
}

.error {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	padding: 0px 15px;
}

.errorH1 {
	color: var(--tipstory-blue);
	font-size: 21px;
}
