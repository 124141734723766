/* Icons */
.icons {
	display: flex;
	align-items: center;
	margin-top: 10px;
	padding: 0;
}

.iconsLi {
	display: flex;
	align-items: center;
	margin-right: 10px;
	color: var(--theme-color-grey);
	font-size: 14px;
	user-select: none;
}

.svg {
	height: 20px;
	width: 20px;
	margin-right: 3px;
}
