.footer {
	margin-top: 30px;
	padding: 20px;
	border-top: 2px solid var(--tipstory-blue);
	display: flex;
	justify-content: space-between;
}

.left {
	display: flex;
	flex-direction: column;
}

.logo {
	height: 50px;
}

.social {
	margin-top: 20px;
	display: flex;
}

.svgLink {
	fill: var(--theme-color-light);
	width: 25px;
	height: 25px;
	margin: 0px 10px;
}

.svgLink:hover,
.svgLink:focus-visible {
	fill: var(--tipstory-blue);
}

.right {
	display: flex;
}

.linksColumn {
	display: flex;
	flex-direction: column;
	padding: 0px 30px;
}

.link {
	line-height: 2;
}

.link:hover,
.link:focus-visible {
	color: var(--tipstory-blue);
	text-decoration: underline;
}

@media (max-width: 768px) {
	.footer {
		flex-direction: column-reverse;
		align-items: center;
	}

	.left {
		margin-top: 15px;
	}

	.linksColumn {
		padding: 0px 15px;
	}
}
