.user {
	display: flex;
	justify-content: center;
	border: 1px solid var(--theme-border-color);
	padding: 15px 15px;
	width: 100%;
	box-sizing: border-box;
	border-radius: 0px 0px 7px 7px;
}

/* Profile Image */
.img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
}

/* Profile */
.profile {
	width: 100%;
	margin-left: 30px;
	margin-bottom: 5px;
}

/* Name */
.name {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 8px;
}

.nameH1 {
	font-size: 22px;
	margin-right: 20px;
}

/* ProfileButton */
.profileButton {
	margin: 2px 0px;
}

/* Headline */
.headline {
	margin-bottom: 15px;
	font-size: 17px;
}

@media (max-width: 455px) {
	.img {
		width: 80px;
		height: 80px;
	}
	.profile {
		margin-left: 15px;
	}
	.name {
		margin-bottom: 5px;
	}
	.nameH1 {
		font-size: 20px;
	}
	.headline {
		margin-bottom: 10px;
		font-size: 15px;
	}
}
