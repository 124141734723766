/* Sidebar */
.sidebar {
	width: 290px;
	margin-right: 50px;
	position: -webkit-sticky; /* for Safari */
	position: sticky;
	top: 52px;
	height: calc(100vh - 52px);
	overflow: hidden;
	scrollbar-width: thin;
	color: var(--theme-color-light);
}

.sidebar::-webkit-scrollbar {
	width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
	background-color: #cccccc;
}

.sidebar:hover {
	overflow-y: scroll;
}

/* Sidebar Title */
.sidebarTitle {
	width: 290px;
	margin-top: 10px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}

/* BreadcombNav */
.breadcomb {
	border-bottom: 1px solid var(--theme-border-color);
}

/* SidebarChapter */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.sidebarChapter {
	composes: preserveOutline;
	width: 290px;
	background: none;
	border: none;
	cursor: pointer;
	border-bottom: 1px solid var(--theme-border-color);
	min-height: 40px;
	display: flex;
	align-items: center;
}

.sidebarChapterRow {
	padding: 7px 5px 5px 13px;
	display: flex;
}

.sidebarChapter:hover,
.sidebarChapter:focus-visible {
	background: var(--theme-hover-background-color);
}

.sidebarSvgDiv {
	margin-top: 2px;
	display: flex;
	margin-right: 7px;
	fill: var(--theme-color);
}

.sidebarSvg {
	width: 15px;
	height: 15px;
}

.chapterH1 {
	font-size: 14px;
	font-weight: 500;
	text-align: left;
}

.emptyMsg {
	user-select: none;
	margin-top: 10px;
	margin-left: 13px;
}

@media (min-width: 1061px) {
	.hideInDesktop {
		display: none;
	}
}

/* Mobile View */

/* Mobile sidebar */
.mobileSidebar {
	margin-top: 20px;
	width: 100%;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	color: var(--theme-color-light);
	box-sizing: border-box;
	padding: 15px 0px 15px 0px;
	min-height: 250px;
}

@media (max-width: 1060px) {
	.hideInMobile {
		display: none;
	}
	.sidebarTitle {
		box-sizing: border-box;
		width: 100%;
		margin-top: 0;
		padding-left: 13px;
		text-align: left;
	}
	.sidebarChapter {
		width: 100%;
	}
}
