.scholarBadge {
	position: relative;
}

.year {
	position: absolute;
	left: 0;
	top: 0;
	user-select: none;
	font-size: 6px;
	font-weight: bold;
}

.beginner {
	fill: var(--tipstory-blue);
}

.bronzeYear {
	fill: #f7894a;
}

.silverYear {
	fill: #ccc;
}

.goldYear {
	fill: #ffb900;
}

.bronze {
	animation: bronzeFilter 10s infinite;
}

@keyframes bronzeFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(247, 137, 74));
		filter: drop-shadow(0px 0px 1.5px rgb(247, 137, 74));
		stroke: rgb(247, 137, 74);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

.silver {
	animation: silverFilter 10s infinite;
}

@keyframes silverFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(204, 204, 204));
		filter: drop-shadow(0px 0px 1.5px rgb(204, 204, 204));
		stroke: rgb(204, 204, 204);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

.gold {
	animation: goldFilter 10s infinite;
}

@keyframes goldFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(255, 185, 0));
		filter: drop-shadow(0px 0px 1.5px rgb(255, 185, 0));
		stroke: rgb(255, 185, 0);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}
