.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.nav {
	display: flex;
	align-items: center;
	padding: 5px 5px;
}

.svgDiv {
	display: flex;
	align-items: center;
	fill: var(--theme-color);
	user-select: none;
}

.svgIcon {
	margin-top: 2px;
	height: 11px;
	width: 11px;
}

.button {
	composes: preserveOutline;
	user-select: none;
	border: none;
	background: none;
	display: flex;
	align-items: center;
	padding: 8px 9px;
	cursor: pointer;
	border-radius: 7px;
	font-size: 14px;
	font-weight: 500;
}

.button:hover,
.button:focus-visible {
	background: var(--theme-hover-background-color);
}
