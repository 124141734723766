.conextMenuModal {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--theme-modal-background-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}

.div {
	position: relative;
	width: 250px;
	background-color: var(--theme-background-color);
	padding: 20px 20px 20px 20px;
	border-radius: 10px;
}

.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.btnTimes {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: none;
	border: 1px solid var(--theme-border-color);
}

.btnTimes:hover,
.btnTimes:focus {
	background: var(--theme-hover-background-color);
}

.timesSvg {
	width: 15px;
	height: 15px;
}

.img {
	width: 100px;
}

.error {
	color: rgb(245, 35, 45);
}

.h1 {
	font-size: 18px;
}

.marginBottom {
	margin-bottom: 10px;
}

.flexRight {
	display: flex;
	justify-content: flex-end;
}

.flexSpaceBetween {
	display: flex;
	justify-content: space-between;
}
