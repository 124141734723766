.section {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--theme-modal-background-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}

.div {
	position: relative;
	width: 100%;
	max-width: 660px;
	background-color: var(--theme-background-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 20px 10px 20px;
	border-radius: 10px;
	border: 1px solid var(--theme-border-color);
}

.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.btnTimes {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: none;
}

.btnTimes:hover,
.btnTimes:focus {
	background: var(--theme-hover-background-color);
}

.timesSvg {
	width: 20px;
	height: 20px;
}

.box {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px 0px 40px 0px;
}

.maxWidth {
	max-width: 420px;
}

.supportTextDiv {
	margin-top: 10px;
	font-size: 13px;
}

.supportTextUl {
	word-wrap: break-word;
}

.error {
	color: var(--tipstory-blue);
	font-weight: 500;
	padding: 0px 15px;
	margin-bottom: 10px;
}

.linkInput {
	background: none;
	outline: none;
	padding: 5px;
	border-radius: 5px;
	font-size: 15px;
	width: 320px;
	border: 1px solid var(--theme-border-color);
}

.linkInput:focus {
	border: 1px solid var(--tipstory-blue);
}

.marginLeft10 {
	margin-left: 10px;
}

.iframeDiv {
	width: 100%;
	margin-top: 10px;
	max-height: 450px;
	overflow: auto;
}

.imageDiv {
	margin-top: 10px;
	max-height: 450px;
	overflow: auto;
	display: flex;
	justify-content: center;
}

.imageImg {
	max-width: 450px;
}
