.containerBlock {
	margin-top: 20px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.h1 {
	color: var(--tipstory-blue);
	font-size: 20px;
}

.p {
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

/* SignUpForm */
.buttonDiv {
	display: flex;
	justify-content: center;
	margin-top: 5px;
}

.button {
	padding: 5px 15px 6px 15px;
}

/* ScholarWelcome */
.badgeList {
	margin-top: 2px;
	display: flex;
}

.svg {
	width: 33px;
	height: 33px;
	margin-right: 5px;
}

.bronze {
	animation: bronzeFilter 10s infinite;
}

@keyframes bronzeFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(247, 137, 74));
		filter: drop-shadow(0px 0px 1.5px rgb(247, 137, 74));
		stroke: rgb(247, 137, 74);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

.silver {
	animation: silverFilter 10s infinite;
}

@keyframes silverFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(204, 204, 204));
		filter: drop-shadow(0px 0px 1.5px rgb(204, 204, 204));
		stroke: rgb(204, 204, 204);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

.gold {
	animation: goldFilter 10s infinite;
}

@keyframes goldFilter {
	0% {
		-webkit-filter: none;
		filter: none;
	}
	35% {
		-webkit-filter: none;
		filter: none;
	}
	50% {
		-webkit-filter: drop-shadow(0px 0px 1.5px rgb(255, 185, 0));
		filter: drop-shadow(0px 0px 1.5px rgb(255, 185, 0));
		stroke: rgb(255, 185, 0);
		stroke-width: 3px;
	}
	65% {
		-webkit-filter: none;
		filter: none;
	}
	100% {
		-webkit-filter: none;
		filter: none;
	}
}

.link {
	color: var(--tipstory-blue);
	text-decoration: underline;
}
