.div {
	margin-top: 20px;
	position: sticky;
	top: 72px; /* header 50px, border 2px, margin top 20px */
}

.nav {
	margin-left: 30px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	width: 200px;
}

/* NavRow */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.navRow {
	composes: preserveOutline;
	display: flex;
	align-items: center;
	width: 100%;
	padding: 15px 23px;
	box-sizing: border-box;
	border-bottom: 1px solid var(--theme-border-color);
	color: var(--theme-color-light);
	fill: var(--theme-color-light);
}

.navRow:hover,
.navRow:focus-visible {
	background-color: var(--theme-hover-background-color);
}

.lastNavRow {
	border-bottom: none;
}

.svg {
	width: 16px;
	height: 16px;
	margin-right: 10px;
}

.redDot {
	margin-left: 10px;
	margin-top: 2px;
	height: 8px;
	width: 8px;
	border-radius: 100%;
	background-color: #ed4956;
}

.tikTokIntern {
	margin-top: 20px;
	margin-left: 30px;
	width: 200px;
	padding: 10px 23px;
	box-sizing: border-box;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	color: var(--theme-color-light);
	fill: var(--theme-color-light);
	display: block;
}

.tikTokIntern:hover,
.tikTokIntern:focus-visible {
	background-color: var(--theme-hover-background-color);
}

@media (max-width: 951px) {
	.nav {
		display: none;
	}
	.div {
		display: none;
	}
}
