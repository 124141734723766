.learning {
	display: flex;
	font-size: 15px;
	line-height: normal;
}

/* Main */
.main {
	margin-top: 20px;
	margin-bottom: 60px;
	width: 100%;
	max-width: 700px;
}

/* ErrorMsg */
.error {
	display: flex;
	justify-content: center;
	margin-bottom: 20px;
}

.errorH1 {
	color: var(--tipstory-blue);
	font-size: 21px;
}

@media (max-width: 1060px) {
	.main {
		margin-left: auto;
		margin-right: auto;
	}
}
