@import-normalize; /* bring in normalize.css styles */

body,
ol,
ul,
h1,
button,
p,
pre {
	padding: 0;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Arial,
		Helvetica, sans-serif;
	line-height: normal;
}

p {
	margin-block-start: 1px;
	margin-block-end: 1px;
}

body,
input,
textarea,
select,
option {
	background-color: var(--theme-background-color);
	color: var(--theme-color);
}

h1,
button,
p {
	color: var(--theme-color);
}

input,
textarea {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Arial,
		Helvetica, sans-serif;
	border: 1px solid var(--theme-border-color);
}

a {
	text-decoration: none;
	color: inherit;
}

textarea,
select,
option {
	font-family: inherit;
	color: var(--theme-color);
	font-size: 15px;
	line-height: normal;
	resize: none;
	outline: none;
}

blockquote {
	border-left: 2px solid var(--theme-border-color);
	margin: 10px 0px;
	padding-left: 10px;
	color: var(--theme-color-grey);
}

ol,
ul {
	margin: 0;
	padding-left: 17px;
}

code {
	font-size: 15px;
}

.emoji {
	font-weight: normal;
}

::selection {
	background-color: var(--theme-select-background-color);
}
::-moz-selection {
	background-color: var(--theme-select-background-color);
}

:root {
	--tipstory-blue: rgb(5, 151, 242);
	--interesting: rgb(255, 189, 89);
	--theme-color: rgb(50, 50, 50);
	--theme-hover-background-color: rgb(245, 245, 245);
	--theme-color-light: rgb(87, 87, 87);
	--theme-color-grey: rgb(130, 130, 130);
	--theme-background-color: #ffffff;
	--theme-border-hover-color: rgb(200, 200, 200);
	--theme-border-color: rgb(229, 229, 234);
	--theme-header-background-color: #ffffff;
	--theme-header-border-bottom: rgb(235, 235, 235);
	--theme-select-background-color: rgb(230, 245, 250);
	--theme-modal-background-color: rgba(0, 0, 0, 0.2);
	/* Prism Default Theme */
	--theme-code-background-color: rgb(245, 242, 240);
	--theme-code-comment: slategray;
	--theme-code-punctuation: #999;
	--theme-code-tag: #905;
	--theme-code-attr-name: #690;
	--theme-code-boolean: #905;
	--theme-code-function: #dd4a68;
	--theme-code-property: #905;
	--theme-code-selector: #690;
	--theme-code-important: #e90;
	--theme-code-keyword: #07a;
	--theme-code-char: #690;
	--theme-code-attr-value: #07a;
	--theme-code-variable: #e90;
	--theme-code-operator: #9a6e3a;
}

[theme='dark'] {
	--theme-color: rgb(235, 235, 235);
	--theme-hover-background-color: rgba(255, 255, 255, 0.1);
	--theme-color-light: rgb(200, 200, 200);
	--theme-color-grey: rgb(200, 200, 200);
	--theme-background-color: rgb(25, 25, 25);
	--theme-border-hover-color: rgba(255, 255, 255, 0.4);
	--theme-border-color: rgba(255, 255, 255, 0.2);
	--theme-header-background-color: rgb(38, 38, 38);
	--theme-header-border-bottom: rgba(38, 38, 38, 0.5);
	--theme-select-background-color: rgba(38, 79, 120, 0.5);
	--theme-modal-background-color: rgba(0, 0, 0, 0.5);
	/* Prism Tomorrow Night Theme*/
	--theme-code-background-color: rgb(45, 45, 45);
	--theme-code-comment: #999;
	--theme-code-punctuation: #ccc;
	--theme-code-tag: #e2777a;
	--theme-code-attr-name: #e2777a;
	--theme-code-boolean: #f08d49;
	--theme-code-function: #f08d49;
	--theme-code-property: #f8c555;
	--theme-code-selector: #cc99cd;
	--theme-code-important: #cc99cd;
	--theme-code-keyword: #cc99cd;
	--theme-code-char: #7ec699;
	--theme-code-attr-value: #7ec699;
	--theme-code-variable: #7ec699;
	--theme-code-operator: #67cdcc;
}
