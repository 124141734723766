/* Sidebar */
.sidebar {
	width: 290px;
	color: var(--theme-color-light);
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	min-height: 241px;
}

/* Sidebar Title */
.sidebarTitle {
	width: 290px;
	margin: 10px 0px;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
}

/* BreadcombNav */
.breadcomb {
	border-bottom: 1px solid var(--theme-border-color);
}

/* SidebarChapter */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.sidebarChapter {
	composes: preserveOutline;
	width: 290px;
	background: none;
	border: none;
	cursor: pointer;
	border-bottom: 1px solid var(--theme-border-color);
	min-height: 40px;
	display: flex;
	align-items: center;
}

.sidebarChapterRow {
	padding: 7px 5px 5px 13px;
	display: flex;
}

.sidebarChapter:hover,
.sidebarChapter:focus-visible {
	background: var(--theme-hover-background-color);
}

.sidebarSvgDiv {
	margin-top: 2px;
	display: flex;
	margin-right: 7px;
	fill: var(--theme-color);
}

.sidebarSvg {
	width: 15px;
	height: 15px;
}

.chapterH1 {
	font-size: 14px;
	font-weight: 500;
	text-align: left;
}

@media (min-width: 951px) {
	.hideInDesktop {
		display: none;
	}
}

/* Mobile View */

/* Mobile sidebar */
.mobileSidebar {
	width: 100%;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	color: var(--theme-color-light);
	box-sizing: border-box;
	padding: 15px 0px 0px 0px;
}

@media (max-width: 951px) {
	.hideInMobile {
		display: none;
	}
	.sidebarTitle {
		box-sizing: border-box;
		width: 100%;
		margin-top: 0;
		padding-left: 13px;
		text-align: left;
	}
	.sidebarChapter {
		width: 100%;
	}
}
