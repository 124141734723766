.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.button {
	composes: preserveOutline;
	padding: 9px;
	margin-left: 10px;
	border-radius: 50%;
	background-color: transparent;
	border: 1px solid var(--theme-hover-background-color);
	cursor: pointer;
	fill: var(--theme-color-light);
	display: flex;
	align-items: center;
}

.button:hover,
.button:focus-visible {
	background-color: var(--theme-hover-background-color);
}

.svg {
	width: 16px;
	height: 16px;
}
