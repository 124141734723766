.resource {
	margin-top: 20px;
}

.resourcePanelDiv {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

@media (max-width: 688px) {
	.resourcePanelDiv {
		justify-content: center;
	}
}

/* PartnershipText */
.partnershipText {
	margin-top: 10px;
	background: var(--theme-hover-background-color);
	padding: 15px 20px 15px 20px;
	border-radius: 7px;
}

.p {
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

.link {
	text-decoration: underline;
	color: var(--tipstory-blue);
}
