.input {
	background: none;
	outline: none;
	padding: 5px;
	border-radius: 5px;
	font-size: 15px;
	width: calc(100% - 10px);
	border: 1px solid var(--theme-border-color);
}

.input:focus {
	border: 1px solid var(--tipstory-blue);
}
