.examplesLearnings {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px;
}

.heading {
	color: var(--tipstory-blue);
}

.learningCards {
	margin-top: 20px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	width: 100%;
}

.learningCard {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 10px;
	box-sizing: border-box;
	max-width: 185px;
	width: 100%;
	margin: 0px 10px;
	overflow: hidden;
	margin-bottom: 20px;
}

.learningCard:hover,
.learningCard:focus {
	outline: 3px solid var(--tipstory-blue);
}

.cardTop {
	width: 100%;
	height: 100%;
}

.cardImg {
	width: 100%;
	height: 100%;
	min-height: 125px;
	object-fit: cover;
}

.cardBottom {
	height: 100%;
	background: var(--theme-hover-background-color);
	padding: 5px 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.title {
	font-weight: 500;
	font-size: 14px;
}

.authorContainer {
	display: flex;
	align-items: center;
	margin-top: 3px;
}

.authorImg {
	width: 19px;
	height: 19px;
	border-radius: 50%;
	object-fit: cover;
	margin-right: 5px;
}

.author {
	font-size: 13px;
}

.button {
	font-size: 15px;
	padding: 6px 14px 7px 12px;
}

@media (max-width: 768px) {
	.heading {
		font-size: 25px;
	}
}
