/* Toolbar */
.toolbarDiv {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 5px;
	margin-bottom: 5px;
	position: sticky;
	top: 51px;
	background-color: var(--theme-background-color);
	border-bottom: 1px solid var(--theme-border-color);
}

.verticalDiv {
	border-left: 2px solid var(--theme-border-color);
	height: 20px;
	user-select: none;
	margin-right: 2px;
}

/* Editor */
.editorDiv {
	margin-top: 10px;
	min-height: 170px;
}

/* IconButton */
.svgButton {
	width: 29px;
	height: 29px;
	padding: 7px;
	outline: none;
	border: none;
	cursor: pointer;
	background-color: var(--theme-background-color);
	fill: var(--theme-color);
	margin-right: 2px;
	border-radius: 5px;
}

.svgButton:hover,
.svgButton:focus-visible {
	background-color: var(--theme-hover-background-color);
}

.svgButtonActive,
.svgButtonActive:hover,
.svgButtonActive:focus-visible {
	background-color: var(--theme-select-background-color);
}

.codeDiv {
	color: rgb(235, 235, 235);
	background-color: rgb(25, 25, 25);
	border: 1px solid var(--theme-border-color);
	padding: 5px 20px;
	border-radius: 7px;
}

/* FontColor Dropdown */
.dropdownDiv {
	position: relative;
}

.itemDiv {
	width: 220px;
	position: absolute;
	left: 0px;
	top: 22px;
	z-index: 20;
	background-color: var(--theme-background-color);
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	display: none;
	padding: 7px;
}

.itemDivOpen {
	display: unset;
}

.topItem {
	cursor: pointer;
	border: none;
	outline: none;
	display: block;
	margin: 1px;
	margin-top: 3px;
	width: 100%;
	border-radius: 7px;
	border: 1px solid var(--theme-border-color);
	background: var(--theme-hover-background-color);
	color: var(--theme-color);
	padding: 1px 0px 2px 0px;
	font-size: 14px;
}

.item {
	cursor: pointer;
	border: none;
	outline: none;
	width: 20px;
	height: 20px;
	margin: 1px;
	border-radius: 50%;
	background: none;
	font-size: 16px;
}

/* Emoji Dropdown */
.emojiDiv {
	composes: itemDiv;
	width: 235px;
}

.eachEmojiDiv {
	height: 210px;
	overflow-y: scroll;
	overflow-x: hidden;
	scrollbar-width: thin;
}

.eachEmojiDiv::-webkit-scrollbar {
	width: 5px;
}

.eachEmojiDiv::-webkit-scrollbar-thumb {
	background-color: #cccccc;
}

.emojiText {
	margin-left: 3px;
	font-size: 15px;
}

.emojiDivider {
	margin: 5px 0px 0px 0px;
}

/* Langugage Dropdown */
.eachLanguageDiv {
	composes: eachEmojiDiv;
}

/* Image Dropdown */
.imageDiv {
	composes: itemDiv;
	width: 140px;
}

.imageDivOpen {
	display: unset;
}

.imageItem {
	font-size: 15px;
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border: none;
	background: none;
	outline: none;
}

.imageItem:hover,
.imageItem:focus {
	background: var(--theme-hover-background-color);
}

.itemSvg {
	fill: var(--theme-color);
	width: 15px;
	height: 15px;
	padding: 2px;
	margin-left: 10px;
}

.itemText {
	margin-left: 10px;
}

/* Hoverbar */
.hoverbar {
	padding: 8px;
	position: absolute;
	z-index: 1;
	top: -10000px;
	left: -10000px;
	border: 1px solid var(--theme-border-color);
	background-color: var(--theme-background-color);
	border-radius: 7px;
	width: 248px;
}

.displayFlexCenter {
	display: flex;
	align-items: center;
}

.linkViewDiv {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.linkA {
	font-size: 14px;
	text-decoration: underline;
	color: rgb(17, 85, 204);
}

.linkInput {
	background: none;
	outline: none;
	padding: 5px;
	border-radius: 5px;
	font-size: 15px;
	width: 180px;
}

.linkSaveButton {
	margin-left: 5px;
	width: 50px;
}

/* Code */
.code {
	padding: 0.2em 0.4em;
	background-color: var(--theme-code-background-color);
	border-radius: 6px;
	font-size: 85%;
	color: var(--theme-code-function);
}
