/* Profile */
.profile {
	display: flex;
	margin-top: 10px;
}

/* ProfileIcon */
.profileIcon {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img {
	display: block;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	border: 1.5px solid transparent;
	object-fit: cover;
}

.profileIcon:focus > .img,
.img:hover,
.img:focus {
	border: 1.5px solid var(--tipstory-blue);
	background-color: var(--tipstory-blue);
}

/* Description */
.description {
	width: 100%;
	margin-left: 15px;
	margin-top: 3px;
}

.topLine {
	font-weight: 500;
}

/* FollowButton */
.followButtonDesktop {
	margin-left: 15px;
	margin-top: 3px;
}

.followButtonMobile {
	margin-top: 5px;
}

@media (min-width: 501px) {
	.hideFollowInDesktop {
		display: none;
	}
}

@media (max-width: 500px) {
	.hideFollowInMobile {
		display: none;
	}
}
