.resourcePanelLink {
	display: flex;
}

.resourcePanel {
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	max-width: 344px;
	width: 100%;
	box-sizing: border-box;
	margin-bottom: 12px;
}

.resourcePanel:hover {
	border: 1px solid var(--theme-border-hover-color);
}

@media (max-width: 688px) {
	.resourcePanel {
		max-width: none;
	}
}

.resourcePanelBottom {
	display: flex;
	padding: 8px 10px;
}

.img {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	object-fit: cover;
}

.rightDiv {
	margin-left: 10px;
}

.name {
	font-weight: bold;
	margin-top: -4px;
	margin-bottom: 2px;
}

/* TagList */
.tagList {
	display: flex;
	flex-wrap: wrap;
	padding: 8px 10px;
	border-bottom: 1px solid var(--theme-border-color);
}

.tag {
	color: var(--tipstory-blue);
	border: 1px solid var(--tipstory-blue);
	padding: 1px 6px 3px 6px;
	border-radius: 7px;
	margin-right: 10px;
}

.lastTag {
	margin-right: 0px;
}
