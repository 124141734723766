.buttonGroup {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 10px;
}

.postDiv {
	display: flex;
	align-items: center;
}

.error {
	color: #ff6961;
	font-size: 15px;
	font-weight: 500;
	margin-right: 10px;
}
