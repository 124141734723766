.mainSection {
	border: 1px solid var(--theme-border-color);
	padding: 15px 30px;
	border-radius: 7px;
	margin-bottom: 20px;
}

.title {
	font-size: 15px;
	font-weight: bold;
	width: 100%;
	resize: none;
	outline: none;
	border: none;
	border-bottom: 1px solid var(--theme-background-color);
	padding-bottom: 5px;
	overflow: hidden;
}

.title:hover {
	border-bottom: 1px solid var(--tipstory-blue);
}
