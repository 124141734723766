.followCard {
	width: 185px;
	box-sizing: border-box;
	margin-right: 8px;
	margin-bottom: 10px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	font-size: 14px;
}

/* Top */
.top {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 10px;
	height: 160px;
}

.img {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	object-fit: cover;
}

.nameScholar {
	font-weight: 500;
	margin: 5px 0px;
	display: flex;
	align-items: center;
	text-align: center;
}

.scholarBadge {
	margin-left: 3px;
}

.headline {
	height: 55px;
	text-align: center;
	line-height: 1.2em;
	color: var(--theme-color-light);
	font-size: 13.3px;
	width: 100%;
	overflow: hidden;
}

/* Bottom */
.bottom {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.svgDiv {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	user-select: none;
}

.svgInnerDiv {
	display: flex;
	align-items: center;
}

.svg {
	width: 19px;
	height: 19px;
}

.svgText {
	margin: 0px 5px;
}

.followBtnDiv {
	margin-bottom: 10px;
}
