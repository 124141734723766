.topSection {
	border: 1px solid var(--theme-border-color);
	padding: 15px 30px;
	border-radius: 7px;
	margin-bottom: 20px;
}

.sourceH1 {
	font-size: 16px;
	color: var(--tipstory-blue);
	margin-bottom: 7px;
}
