.div {
	display: flex;
	align-items: center;
	user-select: none;
}

.logo {
	display: block;
	height: 38px;
}

@media (max-width: 340px) {
	.logo {
		height: 30px;
	}
}
