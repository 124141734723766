.scholarCertificate {
	margin-top: 20px;
}

.containerBlock {
	margin-bottom: 10px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.h1 {
	color: var(--tipstory-blue);
	font-size: 20px;
}

.p {
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

.link {
	cursor: pointer;
	text-decoration: underline;
	color: var(--tipstory-blue);
}

.pdfViewer {
	border: none;
	width: 100%;
	height: 500px;
	margin-bottom: 5px;
}

.bottomP {
	font-size: 15px;
	color: var(--theme-color-light);
}
