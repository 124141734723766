.deleteLearning {
	position: relative;
	width: 300px;
	background-color: var(--theme-background-color);
	padding: 20px 20px 20px 20px;
	border-radius: 10px;
}

.title {
	font-size: 18px;
	margin-bottom: 10px;
}

.text {
	margin-bottom: 13px;
}

.buttonGroup {
	display: flex;
	justify-content: space-between;
}

.deleteButton {
	margin-left: 8px;
}
