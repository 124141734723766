.main {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.landing {
	max-width: 1280px;
	width: 100%;
}

/* topLanding */
.topLanding {
	width: 100%;
	box-sizing: border-box;
	padding: 50px 20px;
	display: flex;
}

/* imgDiv */
.imgDiv {
	max-width: 60%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	user-select: none;
}

.joinUsGif {
	max-width: 400px;
	width: 100%;
}

.studentGroupImg {
	margin-top: 30px;
	width: 100%;
}

/* textDiv */
.textDiv {
	width: 100%;
	box-sizing: border-box;
	padding-left: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
}

.headline {
	color: var(--tipstory-blue);
	font-weight: bold;
	font-size: 46px;
}

.subheadline {
	margin-top: 8px;
	font-weight: 500;
	font-size: 20px;
}

.buttonGroup {
	margin-top: 40px;
	display: flex;
	justify-content: center;
}

.buttonLeft {
	margin-right: 20px;
	font-size: 15px;
	padding: 6px 14px 7px 12px;
}

.buttonRight {
	font-size: 15px;
	padding: 6px 14px 7px 11px;
}

@media (max-width: 1250px) {
	.imgDiv {
		max-width: 40%;
	}

	.headline {
		font-size: 35px;
	}

	.subheadline {
		margin-top: 6px;
		font-size: 18px;
	}
}

@media (max-width: 768px) {
	.topLanding {
		flex-direction: column-reverse;
		align-items: center;
		padding: 20px 20px;
	}

	.imgDiv {
		margin-top: 25px;
		max-width: 85%;
	}

	.textDiv {
		padding-left: 0px;
	}

	.headline {
		font-size: 25px;
	}

	.subheadline {
		margin-top: 6px;
		font-size: 16px;
	}

	.buttonGroup {
		margin-top: 15px;
	}
}
