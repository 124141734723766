.learningCard {
	width: 190px;
	box-sizing: border-box;
	padding: 0px 5px;
	margin-right: 3px;
	margin-bottom: 10px;
}

.dropLeft {
	box-shadow: -3px 0px 0px 0px var(--tipstory-blue);
}

.dropRight {
	box-shadow: 3px 0px 0px 0px var(--tipstory-blue);
}

/* Button */
.button {
	outline: none;
	box-sizing: border-box;
	width: 100%;
	height: 150px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	text-align: left;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: transparent;
	font-size: 14px;
}

.dragEffect {
	opacity: 0.5;
}

.button:focus > .bottom {
	background-color: var(--theme-select-background-color);
	border-radius: 0px 0px 7px 7px;
}

/* Link */
.link:hover > .bottom {
	background-color: var(--theme-select-background-color);
	border-radius: 0px 0px 7px 7px;
}

/* Tag */
.top {
	box-sizing: border-box;
	padding: 7px;
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.bookmark {
	width: 15px;
	height: 15px;
}

.tag {
	width: fit-content;
	color: var(--tipstory-blue);
	border: 1px solid var(--tipstory-blue);
	padding: 3px 7px;
	border-radius: 7px;
}

/* Bottom */
.bottom {
	width: 100%;
	box-sizing: border-box;
	padding: 5px 7px 7px 7px;
	border-top: 1px solid var(--theme-border-color);
}

/* Title */
.title {
	font-size: 14px;
}

/* Footer */
.footer {
	margin-top: 1px;
	color: var(--theme-color-grey);
	fill: var(--theme-color-grey);
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.leftFooter {
	display: flex;
	align-items: center;
}

.sourceSvg {
	width: 13px;
	height: 13px;
	margin-right: 3px;
	margin-top: 2px;
}

.svgGroup {
	display: flex;
	align-items: center;
}

.svg {
	width: 14px;
	height: 14px;
	margin: 2px 1px 0px 1px;
}
