/* LinkCard */
.width {
	width: 100%;
	max-width: 450px;
}

.linkCard {
	composes: width;
	min-height: 40px;
	margin-top: 15px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	position: relative;
}

/* CardTop */
.cardTop {
	composes: width;
	height: 200px;
	object-fit: contain;
	border-bottom: 1px solid var(--theme-border-color);
	border-top-left-radius: 7px;
	border-top-right-radius: 7px;
}

/* mainDiv */
.mainDiv {
	padding: 5px 10px 8px 10px;
	opacity: 0.85;
	font-size: 15px;
	line-height: normal;
}

/* ExternalLink */
.link {
	color: var(--tipstory-blue);
	fill: var(--tipstory-blue);
}

.link:focus,
.link:hover {
	text-decoration: underline;
}

.linkSvg {
	width: 12px;
	height: 12px;
	margin-left: 5px;
}

/* Title */
.title {
	font-weight: bold;
}
