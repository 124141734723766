.h1 {
	margin-top: 20px;
	/* background: #9fe9ff; */
	border-top: 2px solid var(--tipstory-blue);
	border-bottom: 2px solid var(--tipstory-blue);
	width: fit-content;
	font-size: 21px;
	padding: 5px 10px;
	transform: rotate(-1deg);
	color: var(--tipstory-blue);
}

.h1Text {
	transform: rotate(1deg);
	position: relative;
	display: inline-block;
}
