/* Icons */
.iconGroup {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}

.icon {
	display: flex;
	align-items: center;
	margin-right: 20px;
	margin-bottom: 2px;
}

.svg {
	margin-right: 5px;
	height: 24px;
	width: 24px;
}

/* Instagram */
.instagramSvg {
	height: 21px;
	width: 21px;
}

.instagramLink {
	fill: var(--theme-color-grey);
}

.instagramLink:hover,
.instagramLink:focus-visible {
	fill: var(--tipstory-blue);
}

@media (max-width: 401px) {
	.iconGroup {
		font-size: 15px;
	}
	.icon {
		margin-right: 10px;
	}
}
