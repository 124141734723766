.tag {
	margin-top: 7px;
}

.tagH1 {
	font-size: 16px;
	color: var(--tipstory-blue);
	margin-bottom: 7px;
}

.optional {
	font-size: 15px;
	color: var(--theme-color-grey);
	font-weight: normal;
}

/* TagInput */
.tagInput {
	width: 100%;
	resize: none;
	outline: none;
	border: none;
	border-bottom: 1px solid var(--theme-background-color);
	padding-bottom: 5px;
	overflow: hidden;
}

.tagInput:hover {
	border-bottom: 1px solid var(--tipstory-blue);
}

/* TagView */
.tagView {
	font-size: 15px;
	outline: none;
	border: none;
	display: flex;
	flex-wrap: wrap;
	cursor: pointer;
	text-align: left;
	background: var(--theme-background-color);
}

.tagSpan {
	color: var(--tipstory-blue);
	border: 1px solid var(--tipstory-blue);
	padding: 2px 8px;
	border-radius: 7px;
	margin-right: 10px;
	margin-bottom: 5px;
}
