.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

/* Primary Button */
.btn {
	composes: preserveOutline;
	font-size: 15px;
	line-height: normal;
	cursor: pointer;
	position: relative;
	color: var(--theme-color-grey);
	background: none;
	border: none;
	padding: 3px 9px;
	border-radius: 5px;
	user-select: none;
}

.btn:active {
	outline: none;
	opacity: 0.9;
}

.btnNormal {
	composes: btn;
	color: var(--theme-color-grey);
	border: 1px solid rgb(180, 180, 180);
	background: var(--theme-hover-background-color);
}

.btnNormal:hover,
.btnNormal:focus {
	background-color: var(--theme-header-border-bottom);
}

.btnPrimary {
	composes: btn;
	color: white;
	border: 1px solid var(--tipstory-blue);
	background-color: rgb(5, 151, 242, 0.9);
	font-weight: 500;
}

.btnPrimary:hover,
.btnPrimary:focus {
	background-color: var(--tipstory-blue);
}

.btnDisable,
.btnDisable:active {
	opacity: 0.6;
	cursor: auto;
}

.btnDisableNormal {
	composes: btnDisable;
	background-color: rgb(240, 240, 240);
}

.btnDisablePrimary {
	composes: btnDisable;
	background-color: var(--tipstory-blue);
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.btnLoading {
	composes: btnDisable;
	color: transparent;
}

.btnLoading:before {
	content: '';
	position: absolute;
	width: 12px;
	height: 12px;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	animation: spin 0.8s linear infinite;
	border: 3px solid rgb(255, 255, 255, 0.5);
	border-top-color: white;
}

/* TextButton */
.btnText {
	composes: btn;
	padding: 0;
	border-radius: 0;
}

.btnText:hover,
.btnText:focus {
	text-decoration: underline;
}

/* SvgButton */
.btnSvg {
	width: 29px;
	height: 29px;
	padding: 7px;
	outline: none;
	border: none;
	cursor: pointer;
	background-color: var(--theme-background-color);
	fill: var(--theme-color);
	border-radius: 5px;
}

.btnSvg:hover,
.btnSvg:focus-visible {
	background-color: var(--theme-hover-background-color);
}

.btnSvgDisable {
	cursor: auto;
	background-color: var(--theme-background-color) !important;
}

/* DropdownButton */
.dropdown {
	position: relative;
}

.dropdownBtn {
	border: none;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: none;
}

.dropdownBtnIcon {
	composes: preserveOutline;
	composes: dropdownBtn;
	width: 36px;
	height: 36px;
	border-radius: 50%;
	margin-left: 10px;
	fill: var(--theme-color-light);
	border: 1px solid var(--theme-hover-background-color);
}

.dropdownBtnIcon:focus-visible,
.dropdownBtnIcon:hover {
	background-color: var(--theme-hover-background-color);
}

.dropdownBtnIconOpen {
	background-color: var(--theme-hover-background-color);
}

.dropdownList {
	position: absolute;
	right: 0;
	width: 150px;
	background-color: var(--theme-background-color);
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	padding: 0px 10px;
	display: none;
}

.dropdownDisplay {
	display: unset;
}

.dropdownItem {
	composes: preserveOutline;
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	padding: 5px 5px;
	margin: 5px 0px;
	cursor: pointer;
	border: none;
	border-radius: 7px;
	background: none;
}

.dropdownItem:hover,
.dropdownItem:focus {
	background: var(--theme-hover-background-color);
}

.dropdownSvg {
	width: 15px;
	height: 15px;
	margin-left: 10px;
	fill: var(--theme-color-light);
}

.dropdownText {
	margin-left: 10px;
	font-size: 14px;
}

.divider {
	border-top: 1px solid var(--theme-border-color);
}

/* FollowButton */
.followingBtn {
	background: var(--theme-hover-background-color) !important;
}
