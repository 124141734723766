.scholarForm {
	margin-top: 20px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.blur {
	opacity: 0.7;
}

.h2 {
	color: var(--tipstory-blue);
	font-size: 20px;
	margin: 0px;
}

.p {
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

.formDiv {
	padding: 10px 15px;
	border: 2px solid var(--theme-border-color);
	border-radius: 7px;
	margin: 10px 0px;
}

.buttonDiv {
	display: flex;
	justify-content: center;
	margin-top: 7px;
}

.button {
	padding: 5px 15px 6px 15px;
}

.error {
	margin-top: 5px;
	color: rgb(245, 35, 45);
	font-weight: 500;
}

/* Input */
.inputDiv {
	margin-bottom: 10px;
}

.label {
	color: var(--tipstory-blue);
	font-size: 16px;
	font-weight: 500;
	margin-left: 2px;
	display: block;
	margin-bottom: 3px;
}

.input {
	box-sizing: border-box;
	width: 100%;
	font-size: 15px;
	line-height: 23px;
	background: none;
	border: none;
	border-bottom: 1px solid var(--theme-border-color);
}

.input:focus {
	outline: none;
	border-bottom: 1px solid var(--tipstory-blue);
}

/* SchoolYearInput */
.schoolYearLabelDiv {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.schoolYearLabel {
	display: flex;
	align-items: center;
	font-size: 15px;
	line-height: 23px;
	margin-left: 2px;
}

.schoolYearLabelItem {
	margin-left: 2px;
}

.otherInput {
	margin-left: 5px;
	width: 150px;
	line-height: normal;
	padding-left: 0px;
}
