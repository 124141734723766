.buttonGroup {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.groupOne,
.groupTwo {
	display: flex;
}

/* LinkButton */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.button {
	composes: preserveOutline;
	user-select: none;
	border: none;
	display: flex;
	align-items: center;
	background: none;
	padding: 8px 9px;
	cursor: pointer;
	border-radius: 7px;
	fill: var(--theme-color-grey);
	color: var(--theme-color-grey);
	font-size: 14px;
	font-weight: bold;
}

.button:hover,
.button:focus-visible {
	background: var(--theme-hover-background-color);
}

.buttonSvg {
	width: 20px;
	height: 20px;
	margin-right: 4px;
}

.buttonSvgS {
	width: 18px;
	height: 18px;
	padding: 1px;
	margin-right: 4px;
}
