.background {
	margin-top: 5px;
	display: flex;
}

.img {
	width: 50px;
	height: 50px;
	border-radius: 50%;
	object-fit: cover;
}

.profile {
	width: 100%;
	margin-left: 20px;
	margin-top: 3px;
}

.topDescription {
	margin-left: 2px;
	color: var(--theme-color-grey);
}

.backgroundInput {
	font-size: 15px;
	width: 100%;
	resize: none;
	outline: none;
	border: none;
	border-bottom: 1px solid var(--theme-background-color);
	padding-bottom: 5px;
	overflow: hidden;
	margin-top: 3px;
	padding-bottom: 3px;
}

.backgroundInput:hover {
	border-bottom: 1px solid var(--tipstory-blue);
}
