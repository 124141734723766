/* Journey */
.journey {
	outline: none;
	width: 100%;
	min-height: 300px;
	padding: 10px 15px;
	border: 1px solid var(--theme-border-color);
	box-sizing: border-box;
	display: flex;
	justify-content: center;
	border-radius: 0px 0px 7px 7px;
}

.journeyContainer {
	max-width: 965px; /* Each journey is 193px, 193px*5 */
	width: 100%;
	display: flex;
	flex-direction: column;
}

.chapterTitle {
	font-size: 14px;
	user-select: none;
	margin-left: 10px;
	margin-bottom: 5px;
}

.chapterContainer {
	display: flex;
	flex-wrap: wrap;
}

.learningTitle {
	font-size: 14px;
	user-select: none;
	margin-left: 10px;
	margin-bottom: 10px;
	margin-top: 10px;
}

@media (max-width: 1072px) {
	.journeyContainer {
		max-width: 772px;
	}
}

@media (max-width: 879px) {
	.journey {
		padding: 10px 0px;
	}
	.journeyContainer {
		max-width: 579px;
	}
}

@media (max-width: 686px) {
	.journeyContainer {
		max-width: 386px;
	}
}
