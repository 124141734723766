/* Profile */
.profile {
	display: flex;
	margin-top: 10px;
}

/* ProfileIcon */
.profileIcon {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.img {
	display: block;
	border-radius: 50%;
	width: 45px;
	height: 45px;
	border: 1.5px solid transparent;
	object-fit: cover;
}

.profileIcon:focus > .img,
.img:hover,
.img:focus {
	border: 1.5px solid var(--tipstory-blue);
	background-color: var(--tipstory-blue);
}

/* Description */
.description {
	width: 100%;
	margin-left: 15px;
	margin-top: 3px;
}

.topLine {
	font-weight: 500;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.scholarBadge {
	margin-left: 3px;
}

.dot {
	white-space: pre;
}

/* FollowButton */
.followButton {
	margin-left: 5px;
	margin-top: 3px;
}

@media (max-width: 500px) {
	.description {
		margin-left: 5px;
	}
}
