.whatStudentsDo {
	background-color: var(--theme-hover-background-color);
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 20px;
}

.heading {
	color: var(--tipstory-blue);
}

.panels {
	margin-top: 10px;
	display: flex;
	width: 100%;
	justify-content: space-around;
}

.panel {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 10px;
	max-width: 333px;
	box-sizing: border-box;
}

.headingEmoji {
	font-weight: normal;
}

@media (max-width: 768px) {
	.heading {
		font-size: 25px;
	}

	.panels {
		flex-direction: column;
		align-items: center;
	}

	.panelHeading {
		font-size: 18px;
	}
}
