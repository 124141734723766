.article {
	padding: 25px 35px;
	max-width: 800px;
	margin: 20px auto;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
}

.h1 {
	font-size: 25px;
}

.a {
	color: var(--tipstory-blue);
}

.trBackgroundColor {
	background-color: var(--theme-border-color);
}
