.chapter {
	width: 190px;
	box-sizing: border-box;
	padding: 4px 4px;
	border: 1px solid transparent;
	margin-right: 3px;
	margin-bottom: 2px;
}

.dropLeft {
	box-shadow: -3px 0px 0px 0px var(--tipstory-blue);
}

.dropRight {
	box-shadow: 3px 0px 0px 0px var(--tipstory-blue);
}

.dropCenter {
	background-color: var(--theme-select-background-color);
	border: 1px solid var(--tipstory-blue);
}

/* Button */
.button {
	outline: none;
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	text-align: left;
	display: flex;
	align-items: center;
	background-color: var(--theme-background-color);
	padding: 0px 12px;
	fill: var(--theme-color);
}

.button:focus {
	background-color: var(--theme-select-background-color);
}

.dragEffect {
	opacity: 0.5;
}

/* Link */
.link:hover {
	background-color: var(--theme-select-background-color);
}

/* Content */
.svgDiv {
	display: flex;
	justify-content: center;
}

.svg {
	width: 16px;
	height: 16px;
}

.title {
	margin-left: 8px;
	font-size: 14px;
}
