.editComment {
	margin-top: 15px;
	display: flex;
}

.nestedProfileImg {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	margin-right: 15px;
}

.profileImg {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	margin-right: 15px;
	object-fit: cover;
}

.textAreaDiv {
	width: 100%;
}

.textarea {
	border: none;
	border-bottom: 1px solid var(--theme-border-color);
	width: 100%;
	font-size: 14px;
	line-height: normal;
	padding: 0;
	padding-bottom: 5px;
	overflow: hidden;
}

.textarea:focus {
	border-bottom: 1px solid var(--tipstory-blue);
}

.buttonGroup {
	display: flex;
	justify-content: flex-end;
	margin-top: 5px;
}

.marginLeft {
	margin-left: 5px;
}
