/* Timeline */
.timeline {
	margin-top: 30px;
	padding-bottom: 5px;
}

.h1 {
	color: var(--theme-color-light);
	font-size: 20px;
}

.year {
	display: flex;
	margin-top: 7px;
	font-weight: 500;
	color: var(--theme-color-light);
	font-size: 15px;
	margin-bottom: 15px;
}

.yearSelect {
	font-weight: 500;
	font-size: 15px;
	color: var(--theme-color-light);
	border: none;
	cursor: pointer;
	background-color: transparent;
	outline: none;
	margin-left: 5px;
	padding: 0px 8px 2px 8px;
	border-bottom: 1px solid transparent;
}

.yearSelct:focus-visible,
.yearSelect:hover {
	border-bottom: 1px solid var(--tipstory-blue);
}

.ul {
	list-style: none;
}

/* List */
.li,
.lastLi {
	position: relative;
	padding-left: 30px;
	padding-bottom: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
	display: flex;
}

.lastLi {
	padding-bottom: 15px;
}

/* Vertical Line */
.li::before {
	content: '';
	position: absolute;
	width: 3px;
	height: calc(100% - 18px);
	background: var(--theme-color-light);
	top: 20px;
	left: 0px;
}

.liActive::before {
	background: var(--tipstory-blue);
}

/* Circle */
.liDiv {
	width: 83px;
	margin-right: 10px;
}

.liDiv:before {
	content: '';
	position: absolute;
	width: 16px;
	height: 16px;
	left: -9.5px;
	border-radius: 100%;
	background: var(--theme-hover-background-color);
	border: 3px solid var(--theme-color-light);
	z-index: 1;
}

.liDivActive {
	color: var(--tipstory-blue);
}

.liDivActive:before {
	border-color: var(--tipstory-blue);
}

/* Badge */
.svg {
	width: 33px;
	height: 33px;
}

.scholarBadge {
	margin-top: -2px;
}

/* Activate Button */
.badgeButton {
	margin-left: 20px;
}

.activateButtonDiv {
	position: relative;
}

.activateButton {
	height: 30px;
}

.activateButtonDot {
	position: absolute;
	right: -4px;
	top: -4px;
	height: 10px;
	width: 10px;
	border-radius: 100%;
	background-color: #ed4956;
}

.error {
	color: var(--tipstory-blue);
	font-weight: 500;
	font-size: 15px;
	margin-top: 10px;
}
