.user {
	display: flex;
	padding: 0px 15px;
	max-width: 600px;
	width: 100%;
	box-sizing: border-box;
	justify-content: center;
}

/* Profile Image */
.img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	object-fit: cover;
}

/* Profile */
.profile {
	width: 100%;
	margin-left: 30px;
	margin-bottom: 5px;
}

/* Name */
.name {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin-bottom: 8px;
}

.nameScholar {
	display: flex;
	align-items: center;
	margin-right: 20px;
}

.nameH1 {
	font-size: 22px;
}

.scholarBadge {
	margin-left: 5px;
}

/* ProfileButton */
.profileButton {
	margin: 2px 0px;
}

/* Headline */
.headline {
	margin-bottom: 15px;
	font-size: 17px;
}

@media (max-width: 401px) {
	.img {
		width: 80px;
		height: 80px;
	}
	.profile {
		margin-left: 15px;
	}
	.nameScholar {
		margin-right: 10px;
	}
	.nameH1 {
		font-size: 20px;
	}
	.headline {
		font-size: 15px;
	}
}
