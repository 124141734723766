.containerBlock {
	margin-top: 20px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.h1 {
	color: var(--tipstory-blue);
	font-size: 20px;
}

.p {
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

.link {
	color: var(--tipstory-blue);
	text-decoration: underline;
}

/* Instruction */
.blur {
	user-select: none;
	opacity: 0.5;
}

.instructionP {
	font-size: 15px;
	color: var(--theme-color-light);
	margin-bottom: 5px;
}
