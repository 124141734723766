.pinLearning {
	border-top: 1px solid var(--theme-border-color);
	width: 100%;
	padding: 0px 0px 40px 0px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.pinLearningContainer {
	max-width: 700px;
	width: 100%;
}
