.header {
	position: sticky;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 10;
	background-color: var(--theme-header-background-color);
	height: 50px;
	border-bottom: 2px solid var(--theme-header-border-bottom);
}

.nav {
	height: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 20px;
}

.dropdownBtnImg {
	width: 15px;
	height: 15px;
	fill: inherit;
}

@media (max-width: 620px) {
	.nav {
		padding: 0px 10px;
	}
}
