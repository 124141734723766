.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.width {
	width: 169px;
}

.nav {
	composes: width;
	position: relative;
}

.nav:after {
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-top-color: var(--theme-color-light);
	border-bottom: none;
	content: '';
	position: absolute;
	right: 0px;
	top: 15px;
}

/* Select Button */
.selectButton {
	width: 100%;
	background: none;
	outline: none;
	cursor: pointer;
	font-size: 15px;
	border: 1px solid transparent;
	border-radius: 4px;
	display: flex;
	align-items: center;
	color: var(--theme-color-light);
	fill: var(--theme-color-light);
	padding: 7px 10px;
	margin-left: 10px;
	font-weight: 500;
}

.selectButton:focus,
.selectButton:hover {
	border: 1px solid var(--theme-border-color);
}

.svgDiv {
	width: 16px;
	height: 16px;
}

.selectText {
	margin-left: 10px;
}

/* Dropdown List */
.dropdownList {
	width: 162px;
	position: absolute;
	left: 10px;
	background-color: var(--theme-background-color);
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
}

.dropdownItem {
	width: 100%;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	font-size: 15px;
	display: flex;
	align-items: center;
	color: var(--theme-color-light);
	fill: var(--theme-color-light);
	padding: 7px 10px;
}

.dropdownItem:focus,
.dropdownItem:hover {
	background: var(--theme-hover-background-color);
}

.dropdownText {
	margin-left: 10px;
}

@media (max-width: 450px) {
	.width {
		width: 65px;
	}

	.selectText {
		display: none;
	}
}
