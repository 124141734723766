.breadcomb {
	display: flex;
	align-items: center;
	padding: 5px 5px;
}

/* Right Arrow  */
.rightArrow {
	fill: var(--theme-color);
	user-select: none;
	height: 11px;
	width: 11px;
	padding: 0px 2px;
}

.rightArrowSvg {
	display: block;
}
