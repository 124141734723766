/* SharingOverview */
.sharingOverview {
	margin-top: 20px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.h1 {
	font-size: 20px;
	color: var(--theme-color-light);
}

.year {
	display: flex;
	margin-top: 7px;
	font-weight: 500;
	color: var(--theme-color-light);
	font-size: 15px;
}

.yearSelect {
	font-weight: 500;
	font-size: 15px;
	color: var(--theme-color-light);
	border: none;
	cursor: pointer;
	background-color: transparent;
	outline: none;
	margin-left: 5px;
	padding: 0px 8px 2px 8px;
	border-bottom: 1px solid transparent;
}

.yearSelct:focus-visible,
.yearSelect:hover {
	border-bottom: 1px solid var(--tipstory-blue);
}

.bottomDiv {
	margin-top: 5px;
	margin-bottom: 5px;
}

.totalDiv {
	display: flex;
	font-size: 17px;
	color: var(--theme-color-light);
	white-space: pre-wrap;
}

.textDiv {
	width: 190px;
}

/* Month */
.month {
	margin-top: 10px;
	font-weight: 500;
	color: var(--tipstory-blue);
	margin-bottom: 5px;
}

/* Learning */
.learning {
	display: flex;
}

.learningH1 {
	font-size: 15px;
	color: var(--theme-color-light);
}

.learningH1:hover,
.learningH1:focus-visible,
.link:hover,
.link:focus-visible {
	color: var(--tipstory-blue);
	text-decoration: underline;
}

.svg {
	margin-top: 3px;
	margin-right: 7px;
	width: 17px;
	height: 17px;
	fill: var(--theme-color-light);
}
