.svgDiv {
	width: 26px;
	height: 26px;
}

.svg {
	padding: 3px;
	width: 20px;
	height: 20px;
}

.svgChecked {
	fill: var(--tipstory-blue);
}

.svgUnchecked {
	fill: var(--theme-color-light);
}
