.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.item {
	composes: preserveOutline;
	font-size: 15px;
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border: none;
	background: none;
}

.item:hover,
.item:focus {
	background: var(--theme-hover-background-color);
}

.itemSvg {
	fill: var(--theme-color);
	width: 19px;
	height: 19px;
	margin-left: 10px;
}

.itemText {
	margin-left: 8px;
}
