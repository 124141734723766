.sidebarRow {
	width: 290px;
	padding: 7px 5px 5px 13px;
	box-sizing: border-box;
	border-bottom: 1px solid var(--theme-border-color);
}

.sidebarRow:hover,
.sidebarRow:focus-visible {
	background: var(--theme-hover-background-color);
}

.sidebarRowDiv {
	padding-right: 8px;
}

/* Header */
.header {
	margin-top: 1px;
	margin-bottom: 4px;
	display: flex;
	flex-wrap: wrap;
	margin-right: 5px;
}

.tag {
	color: var(--tipstory-blue);
	border: 1px solid var(--tipstory-blue);
	padding: 0px 5px 1px 5px;
	border-radius: 7px;
}

/* Title */
.title {
	display: flex;
	justify-content: space-between;
}

.leftTitle {
	display: flex;
}

.titleSvg {
	margin-right: 7px;
	fill: var(--theme-color);
	margin-top: 1px;
}

.sidebarSvg {
	width: 14px;
	height: 14px;
}

.titleH1 {
	font-size: 14px;
	font-weight: 500;
}

.bookmarkSvg {
	width: 15px;
	height: 15px;
	margin-right: 13px;
}

/* Meta */
.meta {
	display: flex;
	justify-content: space-between;
}

.metaDes {
	font-size: 14px;
	color: var(--theme-color-grey);
	display: flex;
	align-items: center;
}

.metaIcons {
	display: flex;
	align-items: center;
	fill: var(--theme-color-grey);
	margin-right: 12px;
}

.metaSvg {
	width: 15px;
	height: 15px;
	margin: 0px 1px;
}

@media (max-width: 1060px) {
	.sidebarRow {
		width: 100%;
	}
}
