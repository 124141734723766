.header {
	margin-top: 40px;
	background: var(--theme-hover-background-color);
	padding: 1px 20px 5px 20px;
	border-radius: 7px 7px 0px 0px;
}

.p {
	margin-top: 5px;
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}
