/* oneTimeTask */
.oneTimeTask {
	margin-top: 20px;
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.h1Div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.h1 {
	font-size: 20px;
	color: var(--theme-color-light);
}

.arrowSvgButton {
	background: none;
	border: none;
	cursor: pointer;
}

.arrowSvg {
	width: 15px;
	height: 15px;
	fill: var(--theme-color-light);
}

.contentDiv {
	margin-top: 10px;
}

/* Task */
.taskDiv {
	font-size: 18px;
	color: var(--theme-color-light);
	display: flex;
	margin-bottom: 13px;
}

.taskRightDiv {
	margin-left: 5px;
}

.checkedTask {
	text-decoration: line-through;
}

.link {
	color: var(--tipstory-blue);
	text-decoration: underline;
}

.blockquote {
	margin-top: 5px;
	margin-bottom: 0px;
	font-size: 17px;
}

/* CheckoutSocialMedia */
.social {
	display: flex;
	margin-top: 6px;
}

.svgLink {
	fill: var(--theme-color-light);
	width: 23px;
	height: 23px;
	margin-right: 15px;
	cursor: pointer;
	background: none;
	border: none;
}

.svgLink:hover,
.svgLink:focus-visible {
	fill: var(--tipstory-blue);
}

.svgLinkActive {
	fill: var(--tipstory-blue);
}

/* ShareWithFriends */
.nominateForm {
	background: var(--theme-hover-background-color);
	border-radius: 7px;
	margin-top: 5px;
}

.formDiv {
	padding: 10px 15px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	margin: 10px 0px;
}

.buttonDiv {
	display: flex;
	justify-content: center;
	margin-top: 7px;
}

.msg {
	margin-top: 5px;
	font-size: 15px;
}

.error {
	margin-top: 5px;
	color: rgb(245, 35, 45);
	font-weight: 500;
	font-size: 16px;
}

/* Input */
.inputDiv {
	margin-bottom: 10px;
}

.label {
	color: var(--tipstory-blue);
	font-size: 16px;
	font-weight: 500;
	margin-left: 2px;
	display: block;
	margin-bottom: 3px;
}

.input {
	box-sizing: border-box;
	width: 100%;
	font-size: 15px;
	line-height: 23px;
	background: none;
	border: none;
	border-bottom: 1px solid var(--theme-border-color);
}

.input:focus {
	outline: none;
	border-bottom: 1px solid var(--tipstory-blue);
}

/* MissionAccomplished */
.missionAccomplishedButton {
	margin-top: 7px;
	width: fit-content;
	position: relative;
}

.missionAccomplishedButtonDot {
	position: absolute;
	right: -4px;
	top: -4px;
	height: 10px;
	width: 10px;
	border-radius: 100%;
	background-color: #ed4956;
}
