.div {
	display: flex;
	align-items: center;
	user-select: none;
}

.marginLeft10 {
	margin-left: 10px;
}

@media (max-width: 650px) {
	.hideInMobile {
		display: none;
	}
}
