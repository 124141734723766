.section {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--theme-modal-background-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}

.displayNone {
	display: none;
}

.div {
	position: relative;
	max-width: 450px;
	width: 100%;
	background-color: var(--theme-background-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 0px 10px 0px;
	border-radius: 10px;
}

.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.btnTimes {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: none;
	border: 1px solid var(--theme-border-color);
}

.btnTimes:hover,
.btnTimes:focus {
	background: var(--theme-hover-background-color);
}

.timesSvg {
	width: 20px;
	height: 20px;
}

.img {
	width: 100px;
}

.h1 {
	font-size: 18px;
	margin-top: 10px;
	margin-bottom: 15px;
}

.a {
	color: var(--theme-color-grey);
	font-weight: bold;
}

.a:hover {
	color: var(--tipstory-blue);
}

.form {
	text-align: center;
}

.errorMsg {
	margin: 0 35px;
	margin-bottom: 5px;
	color: red;
	font-size: 14px;
}

.nonErrorMsg {
	color: var(--tipstory-blue);
}

.regularMsg {
	color: unset;
}

.input,
.submit {
	width: 300px;
	font-size: 15px;
	line-height: 21px;
	border-radius: 8px;
	margin-top: 7px;
	padding: 10px 10px;
}

.input:focus {
	outline: none;
	border: 1px solid var(--tipstory-blue);
}

.inputError {
	border: 1px solid red;
}

.inputError:focus {
	border: 1px solid var(--theme-border-color);
}

.submitDiv {
	position: relative;
}

.submit {
	composes: preserveOutline;
	background-color: var(--tipstory-blue);
	color: white;
	font-weight: 600;
	border: none;
	width: 322px;
	margin-top: 15px;
	cursor: pointer;
}

.submit:focus,
.submit:hover {
	opacity: 0.9;
}

.submitLoading {
	opacity: 0.7 !important;
	color: var(--tipstory-blue);
	cursor: auto;
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.loading:before {
	content: '';
	position: absolute;
	width: 15px;
	height: 15px;
	margin: auto;
	top: 15px;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: 50%;
	border: 3px solid rgb(255, 255, 255, 0.5);
	border-top-color: white;
	animation: spin 0.6s linear infinite;
}

.horizontalDivider {
	width: 80%;
	display: flex;
	align-items: center;
	text-align: center;
	margin: 15px 0;
	color: var(--theme-color-grey);
	font-size: 14px;
}

.horizontalDivider:before,
.horizontalDivider:after {
	content: '';
	flex: 1;
	border-bottom: 1px solid var(--theme-border-color);
}
.horizontalDivider:before {
	margin-right: 10px;
}
.horizontalDivider:after {
	margin-left: 10px;
}

/* Facebook blue: #1877f2 */
.button {
	composes: preserveOutline;
	background-color: #1877f2;
	color: white;
	font-weight: bold;
	font-size: 15px;
	border-radius: 8px;
	border: none;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 7px 12px;
	width: 322px;
	cursor: pointer;
	margin-top: 10px;
	border: 1px solid var(--theme-border-color);
}

.buttonGoogle {
	composes: button;
	margin-top: 0px;
	background-color: var(--theme-hover-background-color);
	color: unset;
}

.buttonImg {
	width: 24px;
	height: 24px;
}

.button:hover,
.button:focus {
	opacity: 0.9;
}

.span {
	margin-left: 10px;
}

.p {
	font-size: 12px;
	color: var(--theme-color-grey);
	margin-top: 20px;
}

.p ~ .p {
	margin-top: 0px;
}

.btnLink {
	composes: preserveOutline;
	background: none;
	border: none;
	font-size: 12px;
	font-weight: bold;
	color: var(--theme-color-grey);
	cursor: pointer;
}

.btnLink:hover,
.btnLink:focus {
	color: var(--tipstory-blue);
}
