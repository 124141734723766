.about {
	margin-top: 20px;
}

.header {
	background: var(--theme-hover-background-color);
	padding: 10px 20px;
	border-radius: 7px;
}

.h1 {
	font-size: 25px;
}

.p {
	line-height: 30px;
	font-size: 18px;
	color: var(--theme-color-light);
}

.tipstory {
	color: var(--tipstory-blue);
}

.footer {
	margin: 30px 0px;
	background: var(--theme-hover-background-color);
	padding: 15px 20px 18px 20px;
	border-radius: 7px;
}

.buttonDiv {
	display: flex;
	justify-content: center;
	margin-top: 5px;
}

.button {
	padding: 7px 13px;
}
