.link {
	margin-left: 10px;
	display: block;
}

.img {
	width: 33px;
	height: 33px;
	border-radius: 50%;
	display: block;
	border: 1.5px solid var(--theme-background-color);
	background-color: var(--theme-background-color);
	object-fit: cover;
}

.link:focus-visible > .img,
.img:hover,
.img:focus-visible {
	border: 1.5px solid var(--tipstory-blue);
	background-color: var(--tipstory-blue);
}
