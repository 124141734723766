.section {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: var(--theme-modal-background-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20;
}

.displayNone {
	display: none;
}

.div {
	position: relative;
	width: 100%;
	max-width: 350px;
	background-color: var(--theme-background-color);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px 20px 10px 20px;
	border-radius: 10px;
	border: 1px solid var(--theme-border-color);
}

.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.btnTimes {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: none;
}

.btnTimes:hover,
.btnTimes:focus {
	background: var(--theme-hover-background-color);
}

.timesSvg {
	width: 20px;
	height: 20px;
}

.box {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0px 0px 10px 0px;
}

.maxWidth {
	max-width: 420px;
}

.saveH1 {
	font-size: 15px;
}

.error {
	color: rgb(245, 35, 45);
}

.buttonGroup {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
}
