.outsideDiv {
	margin-top: 20px;
	display: flex;
}

.tagSearch {
	display: flex;
	align-items: center;
	padding: 10px 15px;
	border: 1px solid var(--theme-border-color);
	border-radius: 10px;
	max-width: 450px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
}

/* Input */
.input {
	width: 100%;
	height: 20px;
	font-size: 15px;
	outline: none;
	border: none;
}

/* History */
.history {
	position: absolute;
	width: 100%;
	border: 1px solid var(--theme-border-color);
	border-radius: 0px 0px 10px 10px;
	top: 35px;
	left: -1px;
	background: var(--theme-background-color);
	z-index: 10;
}

.tag {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.tag:focus,
.tag:hover {
	background: var(--theme-hover-background-color);
}

.lastTag {
	border-radius: 0px 0px 10px 10px;
}

.tagButton {
	width: 100%;
	padding: 7px 15px;
	background: none;
	border: none;
	outline: none;
	text-align: left;
	font-size: 15px;
	color: var(--theme-color);
	fill: var(--theme-color-grey);
}

.tagButton:focus,
.tagButton:hover {
	background: var(--theme-hover-background-color);
}

.historySvg {
	width: 12px;
	height: 12px;
	margin-right: 10px;
}

.removeButton {
	font-size: 14px;
	padding: 0px 10px 0px 5px;
}

/* SearchIcon */
.searchIcon {
	margin-left: 5px;
	height: 20px;
	width: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: none;
	outline: none;
	border: none;
	fill: var(--tipstory-blue);
	cursor: pointer;
}

@media (max-width: 701px) {
	.outsideDiv {
		justify-content: center;
	}
}
