.pre {
	margin: 10px 0px;
	overflow: auto;
	padding: 10px 16px;
	background-color: var(--theme-code-background-color);
	border-radius: 10px;
	tab-size: 1.5em;
	font-size: 14px;
	font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

.code {
	font-size: 14px;
	line-height: 20px;
}
