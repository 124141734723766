.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.width {
	width: 160px;
}

/* div element */
.div {
	composes: width;
	position: relative;
	padding-bottom: 3px;
	border-bottom: 1px solid var(--theme-background-color);
}

.div:after {
	width: 0;
	height: 0;
	border: 6px solid transparent;
	border-top-color: var(--theme-color);
	border-bottom: none;
	content: '';
	position: absolute;
	right: 5px;
	top: 9px;
}

.div:hover,
.div:focus {
	border-bottom: 1px solid var(--tipstory-blue);
}

/* button */
.button {
	composes: width;
	font-size: 15px;
	border: none;
	cursor: pointer;
	display: flex;
	background: var(--theme-background-color);
	outline: none;
}

/* item div */
.itemDiv {
	width: 167px;
	position: absolute;
	left: -11px;
	top: 22px;
	z-index: 10;
	background-color: var(--theme-background-color);
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	display: none;
}

.itemDivOpen {
	display: unset;
}

/* item */
.item {
	composes: preserveOutline;
	font-size: 15px;
	width: 100%;
	height: 35px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border: none;
	background: none;
}

.item:hover,
.item:focus {
	background: var(--theme-hover-background-color);
}

.svg {
	fill: var(--theme-color);
	width: 19px;
	height: 19px;
}

.topSvg {
	composes: svg;
}

.itemSvg {
	composes: svg;
	margin-left: 10px;
}

.itemText {
	margin-left: 10px;
}
