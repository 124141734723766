.header {
	padding: 6px 17px 12px 17px;
	border-bottom: 1px solid var(--theme-border-color);
}

/* TagList */
.tagList {
	display: flex;
	flex-wrap: wrap;
}

.tag {
	color: var(--tipstory-blue);
	border: 1px solid var(--tipstory-blue);
	padding: 1px 8px 3px 8px;
	border-radius: 7px;
	margin-right: 10px;
	margin-top: 6px;
}

.lastTag {
	margin-right: 0px;
}

/* ExternalLink */
.link {
	position: relative;
	font-size: 13px;
	color: var(--tipstory-blue);
	fill: var(--tipstory-blue);
	margin-top: 6px;
}

.link:focus,
.link:hover {
	text-decoration: underline;
}

.linkSvg {
	margin-left: 5px;
	width: 11px;
	height: 11px;
}
