.nav {
	position: relative;
}

/* Select Button */
.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.selectButton {
	composes: preserveOutline;
	padding: 9px;
	margin-left: 10px;
	border-radius: 50%;
	background-color: transparent;
	border: 1px solid var(--theme-hover-background-color);
	cursor: pointer;
	fill: var(--theme-color-light);
	display: flex;
	align-items: center;
}

.selectButton:focus,
.selectButton:hover {
	background-color: var(--theme-hover-background-color);
}

.svg {
	width: 16px;
	height: 16px;
}

/* Dropdown List */
.dropdownList {
	width: 143px;
	position: absolute;
	background-color: var(--theme-background-color);
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
}

.dropdownItem {
	width: 100%;
	outline: none;
	border: none;
	background: none;
	cursor: pointer;
	font-size: 15px;
	display: flex;
	align-items: center;
	color: var(--theme-color-light);
	fill: var(--theme-color-light);
	padding: 7px 10px;
}

.dropdownItem:focus,
.dropdownItem:hover {
	background: var(--theme-hover-background-color);
}

.dropdownText {
	margin-left: 10px;
}

.divider {
	border-top: 1px solid var(--theme-border-color);
}
