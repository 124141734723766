/* Error */
.error {
	width: 100%;
	padding: 0px 20px;
	display: flex;
	justify-content: center;
	color: var(--tipstory-blue);
	font-size: 16px;
}

/* EmptyMsg */
.emptyMsg {
	width: 100%;
	padding: 0px 20px;
	display: flex;
	justify-content: center;
	color: var(--theme-color-grey);
	user-select: none;
}

/* Follow */
.follow {
	width: 100%;
	display: flex;
	justify-content: center;
}

.followContainer {
	margin-top: 20px;
	max-width: 965px; /* Each journey is 193px, 193px*5 */
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

@media (max-width: 1072px) {
	.followContainer {
		max-width: 772px;
	}
}

@media (max-width: 879px) {
	.followContainer {
		max-width: 579px;
	}
}

@media (max-width: 686px) {
	.followContainer {
		max-width: 386px;
	}
}
