.preserveOutline:focus {
	outline: none;
}

.preserveOutline:focus-visible {
	outline: revert;
}

.closeButton {
	composes: preserveOutline;
	position: absolute;
	top: 5px;
	right: 5px;
	display: flex;
	align-items: center;
	border: none;
	cursor: pointer;
	padding: 5px 5px;
	border-radius: 50%;
	background: none;
	border: 1px solid var(--theme-border-color);
}

.closeButton:hover,
.closeButton:focus {
	background: var(--theme-hover-background-color);
}

.img {
	width: 15px;
	height: 15px;
}
