.section {
	padding: 15px 30px 20px 30px;
	border: 1px solid var(--theme-border-color);
	border-radius: 7px;
	margin-top: 20px;
}

.h1 {
	font-weight: 500;
	font-size: 15px;
}

@media (max-width: 700px) {
	.section {
		padding: 15px 20px 20px 20px;
	}
}
